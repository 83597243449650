.flex {
  display: flex;

  &-col {
    flex-direction: column;
  }

  &-1 {
    flex: 1;
  }

  &-sb {
    justify-content: space-between;
  }

  &-sa {
    justify-content: space-around;
  }

  &-aic {
    align-items: 'center';
  }
}

.pad {
  &-h {
    &-xs {
      padding: 0 2rem;
    }
    
    &-sm {
      padding: 0 4rem;
    }

    &-m {
      padding: 0 8rem;
    }
  }

  &-v {
    &-xs {
      padding: 2rem 0;
    }
    
    &-sm {
      padding: 4rem 0;
    }

    &-m {
      padding: 8rem 0;
    }
  }
}

.marg {
  &-c {
    margin: 0 auto;
  }

  &-t {
    &-xs {
      margin-top: 1rem;
    }

    &-sm {
      margin-top: 2rem;
    }

    &-m {
      margin-top: 4rem;
    }

    &-ml {
      margin-top: 6rem;
    }

    &-l {
      margin-top: 10rem;
    }
  }

  &-b {
    &-xs {
      margin-bottom: 1rem;
    }

    &-sm {
      margin-bottom: 2rem;
    }

    &-m {
      margin-bottom: 4rem;
    }

    &-ml {
      margin-bottom: 6rem;
    }

    &-l {
      margin-bottom: 10rem;
    }
  }

  &-l {
    &-xs {
      margin-left: 1rem;
    }

    &-sm {
      margin-left: 2rem;
    }

    &-m {
      margin-left: 4rem;
    }

    &-ml {
      margin-left: 6rem;
    }

    &-l {
      margin-left: 10rem;
    }
  }

  &-r {
    &-xs {
      margin-right: 1rem;
    }

    &-sm {
      margin-right: 2rem;
    }

    &-m {
      margin-right: 4rem;
    }

    &-ml {
      margin-right: 6rem;
    }

    &-l {
      margin-right: 10rem;
    }
  }
}