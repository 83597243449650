p {
  font-size: 1.75rem;
  font-weight: 400;
  margin: 0;
}

h1 {
  font-size: 6rem;
  margin: 0;
  line-height: 7rem;
  background: -webkit-linear-gradient($color-primary, $color-secondary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h2 {
  font-size: 3rem;
  // margin: 0;
  line-height: 3rem;
}

h3 {
  font-size: 2rem;
  margin: 0;
}

a {
  font-size: 1.75rem;
  font-weight: 700;
  margin: 0;
  color: $color-secondary;
  text-decoration: none;
  cursor: pointer;
}

ion-icon {
  color: $color-secondary;
}

.white {
  color: white;
}