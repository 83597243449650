// colors
$color-primary: rgb(246, 185, 59);
$color-secondary: rgb(250, 152, 58);
$color-gray: rgb(189, 195, 199);
$color-white: rgb(255, 255, 255);

// break-points
$mobile: 300px;
$tablet: 950px;
$laptop: 1366px;

