html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  font-size: 1.5rem;
  line-height: 2rem;
  font-family: "Roboto";

  // background waves
  // background-image: url('../static/layered-waves-haikei2.svg');
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
}